import React from "react";
import theme from "theme";
import { Theme, Text, Em, List, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"terms-and-conditions"} />
		<Helmet>
			<title>
				Viridi
			</title>
			<meta name={"description"} content={"Cultivate peace and relaxation as you tend to your virtual succulent garden in Viridi."} />
			<meta property={"og:title"} content={"Viridi"} />
			<meta property={"og:description"} content={"Cultivate peace and relaxation as you tend to your virtual succulent garden in Viridi."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/672a14af9710450018a414e9/images/game-6-removebg-preview.png?v=2024-11-05T13:40:06.191Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/672a14af9710450018a414e9/images/game-6-removebg-preview.png?v=2024-11-05T13:40:06.191Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/672a14af9710450018a414e9/images/game-6-removebg-preview.png?v=2024-11-05T13:40:06.191Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/672a14af9710450018a414e9/images/game-6-removebg-preview.png?v=2024-11-05T13:40:06.191Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/672a14af9710450018a414e9/images/game-6-removebg-preview.png?v=2024-11-05T13:40:06.191Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/672a14af9710450018a414e9/images/game-6-removebg-preview.png?v=2024-11-05T13:40:06.191Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/672a14af9710450018a414e9/images/game-6-removebg-preview.png?v=2024-11-05T13:40:06.191Z"} />
		</Helmet>
		<Components.Header />
		<Section>
			{"    "}
			<Text font="--headline2">
				{"        "}Terms and Conditions{"\n    "}
			</Text>
			{"    "}
			<Text>
				{"        "}
				<Em>
					{"            "}Last Updated: 20.10.2024{"\n        "}
				</Em>
				{"    "}
			</Text>
			{"    "}
			<Text font="--headline3">
				{"        "}1. Acceptance of Terms{"\n    "}
			</Text>
			{"    "}
			<Text>
				By downloading or using virtuecho GAMES, you affirm that you are at least 18 years old or that you have the consent of a parent or guardian. If you are accessing the game on behalf of an organization, you represent that you have the authority to bind that organization to these Terms.{"\n    "}
			</Text>
			{"    "}
			<Text font="--headline3">
				{"        "}2. License to Use the Game{"\n    "}
			</Text>
			{"    "}
			<Text>
				We grant you a limited, non-exclusive, non-transferable, revocable license to access and use virtuecho GAMES for personal, non-commercial purposes. You agree not to modify, distribute, or exploit any part of the game without our express written permission.{"\n    "}
			</Text>
			{"    "}
			<Text font="--headline3">
				{"        "}3. User Responsibilities{"\n    "}
			</Text>
			{"    "}
			<Text>
				{"        "}You are responsible for your use of the game and for any content you create, share, or interact with while using virtuecho GAMES. You agree not to engage in any unlawful or prohibited activities, including but not limited to:{"\n    "}
			</Text>
			{"    "}
			<List list-style-type="circle" as="dot">
				<Text display="inline">
					Harassment or intimidation of other users
				</Text>
				<Text display="inline">
					Impersonation of any person or entity
				</Text>
				<Text display="inline">
					Interference with the operation of the game
				</Text>
			</List>
			{"    "}
			<Text font="--headline3">
				{"        "}4. Intellectual Property{"\n    "}
			</Text>
			{"    "}
			<Text>
				{"        "}All content, trademarks, and other intellectual property in virtuecho GAMES are owned by virtuecho GAMES or its licensors. You may not use, reproduce, or distribute any materials from the game without our prior written consent.{"\n    "}
			</Text>
			{"    "}
			<Text font="--headline3">
				{"        "}5. Limitation of Liability{"\n    "}
			</Text>
			{"    "}
			<Text>
				{"        "}To the fullest extent permitted by law, we shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of virtuecho GAMES.{"\n    "}
			</Text>
			{"    "}
			<Text font="--headline3">
				{"        "}6. Changes to Terms{"\n    "}
			</Text>
			{"    "}
			<Text>
				{"        "}We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting the revised Terms on our website. Your continued use of the game following such changes constitutes your acceptance of the new Terms.{"\n    "}
			</Text>
			{"            "}
		</Section>
		<Components.Footer2 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66fbc4301b94b10024315de0"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});